
interface QuizProgressProps {
    progress: number;
}
const QuizProgress = ({ progress }: QuizProgressProps) => {

    const size = 52;
    const strokeWidth = 6;

    const viewBox = `0 0 ${size} ${size}`;
    const radius = (size - strokeWidth) / 2;
    const circumference = radius * Math.PI * 2;
    const dash = (progress * circumference) / 100;

    return (
        <div className="block w-[52px] h-[52px] m-auto relative">
            <svg width={size} height={size} viewBox={viewBox}>
                <circle
                    fill="none"
                    stroke="#E4EAF0"
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                />
                <circle
                    fill="none"
                    stroke="#EBEB67"
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    strokeDasharray={`${dash} ${circumference - dash}`}
                    strokeLinecap="round"
                    style={{ transition: "all 0.5s" }}
                />
            </svg>
            <div className="w-full h-full absolute top-0 left-0  flex justify-center items-center">
                <span className="font-roobertBold text-nightSky text-xs">{`${progress}%`}</span>
            </div>
        </div>
    );
};

export default QuizProgress;
