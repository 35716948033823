import { StyleVariantProps } from "../../types";

interface ImageWithGradientProps {
	width: string;
	height: string;
	imageUrl: string;
	variant?: string;
	label?: string;
}
const ImageWithGradient = ({ width, height, imageUrl, variant, label}: ImageWithGradientProps) => {
  const variants: StyleVariantProps = {
		gradient: {
			onHover: 'transition-all opacity-0 hover:opacity-100',
			default: ''
		},
		label: {
			small: 'text-[14px]',
			large: 'text-[18px]',
			default:' text-[14px]'
		}
	}

  return (
      <div className={`rounded overflow-hidden ${width} ${height} relative`}>
          <div className="w-full h-full bg-no-repeat bg-cover bg-center-top" style={{backgroundImage: `url(${imageUrl})`}}></div>
          <div className={`w-full h-full absolute top-0 left-0 bg-imageGradient ${( variant ? variants.gradient[variant] : variants.gradient.default)}`}></div>
		  <span className={`absolute bottom-4 left-0 right-0 m-auto font-roobertSemiBold text-white ${( variant ? variants.label[variant] : variants.label.default)}`}>{label}</span>
      </div>
  )
}

export default ImageWithGradient;