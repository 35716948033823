import { useEffect } from 'react';

type Props = {
  [key in keyof WindowEventMap]?: any;
};

export const arraysAreEqual = (a: any[], b: any[]) => {
  return (
    a.length === b.length &&
    a.every((v, i) => v === b[i])
  );
}

export const useGlobalDOMEvents = (props: Props) => {
  useEffect(() => {
    for (let [key, func] of Object.entries(props)) {
      window.addEventListener(key, func, false);
    }
    return () => {
      for (let [key, func] of Object.entries(props)) {
        window.removeEventListener(key, func, false);
      }
    };
  }, [props]);
}
