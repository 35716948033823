import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import endCapImg from './../../assets/images/endcap.jpg';
import Button from '../common/Button';

const EndCap = () => {
  const handleSurveyClick = () => {
    mixpanel.track('Take survey');
    window.open('https://gameon.ly/3/Dt_AExXtU');
  }

  const handleRestartClick = () => {
    mixpanel.track('Restart demo');
    window.location.reload();
  }

  useEffect(() => {
    mixpanel.track('End Cap');
  }, []);

  return (
    <div className="end-cap bg-teal m-0 h-full pt-24 px-10">
      <p className="text-yellow text-lg tracking-tight font-semibold uppercase pb-2">Thank you</p>
      <p className="text-white text-4xl tracking-tight">Thanks for trying out our demo!</p>
      <p><img src={endCapImg} className="w-5/6 h-[356px] mx-auto my-10" alt="" /></p>
      <p className="mb-2"><Button label="Take the survey &rarr;" onClick={handleSurveyClick} /></p>
      <p className="text-nightSky">Or <button onClick={handleRestartClick} className="underline">restart the demo</button></p>
    </div>
  );
}

export default EndCap;
