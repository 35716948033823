import { useContext, useEffect, useState } from 'react';
import { ThemeContext }  from  './../context/ThemeContext';
import bgSvg from './../assets/svg/bg.svg';
import bgLargeSvg from './../assets/svg/large-bg.svg';
import { ReactComponent as ContentBgDark } from './../assets/svg/content-bg-dark.svg';
import { ReactComponent as ContentBgLight } from './../assets/svg/content-bg-light.svg';
import { ReactComponent as CloseDark } from './../assets/icons/close-nightsky.svg';
import { ReactComponent as CloseLight } from './../assets/icons/close-white.svg';
import { useAppSelector } from '../hooks';
import { getQuizId, getShowOverlay, getAllowOverlayScroll, getView } from '../redux/appSlice';
import { getUserCohort } from '../redux/quizSlice';
import ThisOrThat from './quiz/ThisOrThat';
import LoveOrHate from './quiz/LoveOrHate';
import Registration from './common/Registration';
import EndCap from './common/EndCap';
import ProfilePlaceholder from './profile/ProfilePlaceholder';


interface OverlayProps {
	windowWidth: number;
	onClose: () => void;
}
const Overlay = ({ windowWidth, onClose }: OverlayProps) => {
	const theme = useContext(ThemeContext);
	const allowScroll = useAppSelector(getAllowOverlayScroll);
	const showOverlay = useAppSelector(getShowOverlay);
	const view = useAppSelector(getView);
	const quizId = useAppSelector(getQuizId);
	const userCohort = useAppSelector(getUserCohort);

	const [animateIn, setAnimateIn] = useState<boolean>(false);
	const [finishUp, setFinishUp] = useState<boolean>(false);
	
	const maskStyles = {
		WebkitMaskImage: `url(${windowWidth > 1024 ? bgLargeSvg : bgSvg})`,
		maskImage: `url(${windowWidth > 1024 ? bgLargeSvg : bgSvg})`,
		WebkitMaskSize: '100%',
		maskSize: '100%',
		WebkitMaskRepeat: 'no-repeat',
		maskRepeat: 'no-repeat',
		overflow: `hidden ${allowScroll ? 'auto' : 'hidden'}`
	}

	const showOverlayView = () => {
        switch (view) {
            case 'battle':
                return <ThisOrThat id={quizId} completeQuiz={finishUp} />
            case 'lovehate':
                return <LoveOrHate id={quizId} completeQuiz={finishUp} />
            case 'registration':
                return <Registration cohort={userCohort} />
            case 'profile':
                return <ProfilePlaceholder cohort={userCohort} />
            case 'end-cap':
                return <EndCap />
        }
    }

	useEffect(() => {
		if(showOverlay) {
			setAnimateIn(true);
		} else {
			setAnimateIn(false);
		}
	}, [showOverlay])   
	
	// useEffect(() => {
	// 	setAnimateIn(true);
	// })   


	return (

		<div className={`${theme?.blueBg ? 'bg-nightSky' : 'bg-white'} w-full h-full absolute bottom-0 z-20 transition-transform ease-in-out duration-500 ${animateIn ? ' translate-y-0' : 'translate-y-full'}`} style={maskStyles}>

			{theme?.blueBg ? (
				<ContentBgDark className="absolute top-[50%] translate-y-[-40%] left-0 pointer-events-none" />
				) : (
				<ContentBgLight className="absolute top-[50%] translate-y-[-40%] left-0 pointer-events-none" />
			)}
			
			{showOverlay && (
				<button className={`${theme?.blueBg ? 'text-white' : 'text-nightSky'} font-bold absolute top-4 right-4 z-30`} onClick={() => {
					setAnimateIn(false);
					setFinishUp(true);
					setTimeout(() => {
						setFinishUp(false);
						onClose();
					}, 1000);
				}}>
					{theme?.blueBg ? (
						<CloseLight />
					) : (
						<CloseDark />
					)}
				</button>
			)}
			<div className={`h-full transition-transform ease-bounce duration-700 delay-100 ${animateIn ? ' translate-y-0 opacity-100' : 'translate-y-10'}`}>
				{showOverlayView()}
			</div>

		</div>
	);
};

export default Overlay;
