import { v4 as uuidv4 } from 'uuid';
import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setShowOverlay } from "../../redux/appSlice";
import { eyebrowStyles, quizContentWrapper, quizFooterWrapper, quizHeadlineStyles, quizHeadlineWrapper, quizSubheaderStyles, quizWrapper } from "../../styles";
import Button from "../common/Button";
import { ReactComponent as ArrowRightIcon } from './../../assets/icons/arrow-right.svg';
import { ThemeContext }  from  './../../context/ThemeContext';
import ThisOrThatImage from "./ThisOrThatImage";
import { ThisOrThatContestant } from "../../types";
import { quizDone, getQuizContent } from "../../redux/quizSlice";
import { getSessionId, updateChat } from "../../redux/chatSlice";
import { WebSocketContext } from "../../context/WebSocketContext";

import beyonceImg from "../../assets/images/beyonce-test.png";
import mixpanel from 'mixpanel-browser';


interface ThisOrThatProps {
  id: string;
  completeQuiz: boolean;
}

const ThisOrThat = ({ id, completeQuiz }: ThisOrThatProps) => {
    const [isIntro, setIsIntro] = useState<boolean>(true);
    const [animateOutIntro, setAnimateOutIntro] = useState<boolean>(false);
    const [animateInContent, setAnimateInContent] = useState<boolean>(false);
    const [animateInResults, setAnimateInResults] = useState<boolean>(false);
    const [hasResults, setHasResults] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>(2);
    const [content, setContent] = useState<any | null>(null);
    const [contestants, setContestants] = useState<Array<ThisOrThatContestant> | null>(null);
    const [currentWinner, setCurrentWinner] = useState<ThisOrThatContestant | null>(null);
    const [leftContestant, setLeftContestant] = useState<ThisOrThatContestant | null>(null);
    const [rightContestant, setRightContestant] = useState<ThisOrThatContestant | null>(null);
    const [nextContestant, setNextContestant] = useState<ThisOrThatContestant | null>(null);
    const [loser, setLoser] = useState<string>('');
    const dispatch = useAppDispatch();
    const theme = useContext(ThemeContext);
    const quizContent = useAppSelector(getQuizContent);

    const sessionId = useAppSelector(getSessionId);
    const ws = useContext(WebSocketContext);


    const handleClick = (winnerPosition: string) => {
      setLoser(winnerPosition === 'left' ? 'right' : 'left');

      mixpanel.track('Style Battle Choice Made', {
        leftContestant,
        rightContestant,
        winner: winnerPosition === 'left' ? leftContestant : rightContestant,
        loser: winnerPosition === 'left' ? rightContestant : leftContestant
      })
      
      if(nextContestant) {
        setTimeout(() => {
          if(winnerPosition === 'left') {
            setCurrentWinner(leftContestant)
            setRightContestant(nextContestant);
          } else {
            setCurrentWinner(rightContestant)
            setLeftContestant(nextContestant);
          }
          setLoser('');
          const nextRound = currentIndex + 1;
          setCurrentIndex(nextRound);
        }, 1000);
      } else {
        //set final winner
        setCurrentWinner(winnerPosition === 'left' ? leftContestant : rightContestant);
        setTimeout(() => {
          setAnimateInContent(false);
        }, 1000);
        setTimeout(() => {
          setHasResults(true);
          setAnimateInResults(true);
        }, 1500);
      }
    }
    
    const handleComplete = () => {
      mixpanel.track('Style Battle Winner', {
        winner: currentWinner
      })
      if(currentWinner) {

        const message = {
          questionId: id,
          answerId: currentWinner.slug,
          text: currentWinner.title
        }
        if(ws && sessionId) {
          ws.sendMessage(sessionId, message, 'answer', true);
        }

        //only display results in chat if the quiz is completed
        if(hasResults) {
          const formattedMessage = {
            id: uuidv4(),
            direction: 'outgoing',
            type: 'image',
            imageUrl: currentWinner.asset ? currentWinner.asset.file.url : undefined,
            message: currentWinner.title
          }
          dispatch(updateChat([formattedMessage]));
        }
        dispatch(quizDone('style-battle'));
        dispatch(setShowOverlay(false));
      }
    }

    useEffect(() => {
      if(completeQuiz) {
        handleComplete();
      }
    // eslint-disable-next-line
    }, [completeQuiz])

    useEffect(() => {
      if(theme && isIntro) {
        theme.setBlueBg(true);
      }
    }, [isIntro, theme])
    
    useEffect(() => {
      if(contestants && currentIndex > 2) {
        if(currentIndex < contestants.length) {
          setNextContestant(contestants[currentIndex]);
        } else {
          setNextContestant(null);
        }
      }
    }, [currentIndex, contestants])
    
    useEffect(() => {
      if(content && content.choices) {
        setContestants(content.choices);
        setCurrentWinner(content.choices[0]);
        setLeftContestant(content.choices[0]);
        setRightContestant(content.choices[1]);
        setNextContestant(content.choices[2]);
      }
    }, [content])
  
    useEffect(() => {
      let content = quizContent.filter(item => item.slug === id);
      
      if(content.length <= 0) {
        content = [{
          slug: 'battle',
          choices: [
            {
              title: "Beyonce",
              slug: "beyonce",
              asset: {
                file: {
                  url: beyonceImg
                }
              }
            },
            {
              title: "Billie Eilish",
              slug: "billie",
              asset:  {
                file: {
                  url: beyonceImg
                }
              }
            },
            {
              title: "Zendaya",
              slug: "zendaya",
              asset:  {
                file: {
                  url: beyonceImg
                }
              }
            },
            {
              title: "Lizzo",
              slug: "lizzo",
              asset:  {
                file: {
                  url: beyonceImg
                }
              }
            },
            {
              title: "Alicia Keys",
              slug: "alicia-keys",
              asset:  {
                file: {
                  url: beyonceImg
                }
              }
            }
          ]
        }]
      }

      const copyContent = {
        intro: {
          headline: 'Style Battle!',
          paragraph1: 'A simple game:',
          paragraph2: 'Choose your favorite<br />celebrity looks.'
        },
        play: {
          headline: 'Style Battle!',
          subheader: 'Choose your favorite celebrity looks.',
        }
      }

      setContent({...copyContent, ...content[0]});
    }, [id, quizContent])

    return (
      content && (
        <div className="this-or-that relative w-full h-full m-0">
          {!hasResults ? isIntro ? (
            <div className={`${quizWrapper} pt-[60px] transition-opacity duration-300 ${animateOutIntro ? 'opacity-0' : 'opacity-100'}`}>
              <div className={`${quizHeadlineWrapper}`}>
                <h2 className={`${quizHeadlineStyles} text-white`}>{content.intro.headline}</h2>
              </div>
              <div className={`${quizContentWrapper}`}>
                <div className={`block text-center m-auto w-[85%] `}>
                  <p className={`block text-white text-2xl font-roobertBold`}>{content.intro.paragraph1}</p>
                  <p className={`block text-white text-2xl`} dangerouslySetInnerHTML={{__html: content.intro.paragraph2}}></p>
                </div>
              </div>
              <div className={`${quizFooterWrapper}`}>
              <Button onClick={() => {
                  setAnimateOutIntro(true);
                  mixpanel.track("Start Style Battle");
                  setTimeout(() => {
                    setIsIntro(false);
                    theme?.setBlueBg(false);
                    setAnimateInContent(true);
                  }, 300);
              }} label="Let's do this!" size="large" variant="tertiary" symbolAfter={<ArrowRightIcon className="ml-2 mt-1 fill-white" style={{width: 16, height: 20}} />} />
              </div>
            </div>
           ) : (
            <div className={`${quizWrapper} pt-[60px] transition-opacity duration-300 ${animateInContent ? 'opacity-100' : 'opacity-0'}`}>
              <div className={`${quizHeadlineWrapper}`}>
                <h2 className={`${quizHeadlineStyles} text-nightSky mb-2`}>{content.play.headline}</h2>
                <p className={`${quizSubheaderStyles} max-w-[200px] mx-auto text-nightSky`}>{content.play.subheader}</p>
              </div>

              <div className={`${quizContentWrapper} pt-[80px] px-2 flex`}>
                {leftContestant && (
                  <ThisOrThatImage data={leftContestant} direction="left" nextContestant={nextContestant} isLoser={loser === 'left'} numImages={(contestants ? (contestants?.length - currentIndex) : undefined)} onClick={
                    () => handleClick('left')} />
                )}
                {rightContestant && (
                  <ThisOrThatImage data={rightContestant} direction="right" nextContestant={nextContestant} isLoser={loser === 'right'} numImages={(contestants ? (contestants?.length - currentIndex) : undefined)} onClick={
                    () => handleClick('right')} />
                )}
              </div>
              
              <div className={`${quizFooterWrapper}`}></div>
            </div>
          ): (
            <div className={`${quizWrapper} bg-no-repeat bg-cover bg-center pt-[60px] transition-opacity duration-300 ${animateInResults ? 'opacity-100' : 'opacity-0'}`} style={{backgroundImage: `url(${currentWinner?.asset?.file?.url || beyonceImg})`}}>
              <div className={`${quizHeadlineWrapper}`}>
              </div>
              <div className={`${quizContentWrapper}`}>
              </div>
              <div className={`${quizFooterWrapper} bg-imageGradientFull`}>
                <p className={`${eyebrowStyles} text-yellow`}>Winner</p>
                <h2 className={`${quizHeadlineStyles} text-white px-4 mb-[26px]`}>{currentWinner?.title}!</h2>
                <Button onClick={handleComplete} label="Continue" size="large" symbolAfter={<ArrowRightIcon className="ml-2 mt-1 fill-white" style={{width: 16, height: 20}} />} />
              </div>
            </div>
          )}
           
        </div>
      )
    );
  };
  
  export default ThisOrThat;
  