import { useEffect, useState } from "react";

interface StackShadowProps {
    update?: boolean;
    numImages?: number;
}
const StackShadows = ({update, numImages} : StackShadowProps) => {

    const [animateOut, setAnimateOut] = useState<boolean>(false);
    const [stackHeight, setStackHeight] = useState<string>('');

    const newStackHeight = (numImages: number) => {
        let containerHeight = stackHeight !== '' ? stackHeight : 'h-[17px]';
        switch (numImages) {
            case 3:
                containerHeight = 'h-[12px]'
                break;
            case 2:
                containerHeight = 'h-[7px]'
                break;
            case 1:
            case 0:
            case -1:
                containerHeight = 'h-[0px]'
                break;
        }

        return containerHeight;
    }

    useEffect(() => {
        if(numImages) {   
            let containerHeight = 'h-[17px]';

            switch (numImages) {
                case 2:
                    containerHeight = 'h-[12px]';
                    break;
                case 1:
                    containerHeight = 'h-[7px]';
                    break;
                case 0:
                case -1:
                    containerHeight = 'h-[0px]';
                    break;
            
                default:
                    containerHeight = 'h-[17px]';
            }

            setStackHeight(containerHeight);
        } else {
            setStackHeight('h-[0px]');
        }
    }, [numImages])
 
    useEffect(() => {
      if(update) setAnimateOut(true);

      setTimeout(() => {
        setAnimateOut(false);
      }, 1000);
    }, [update])
    
    const cardStyles = "m-auto h-[5px] bg-[#E9F7FB] bg-stackCardGradient opacity-40 overflow-hidden rounded-[0px_0px_8px_8px] relative ";
    const shadowStyles = "w-full h-full absolute top-0 left-0 overflow-hidden rounded-[0px_0px_8px_8px] bg-stackShadowGradient opacity-20";

    return (
        <div className={`w-full text-center overflow-hidden duration-500 ${(numImages && animateOut) ? newStackHeight(numImages) : stackHeight} border border-transparent`}>
            {Array(8).fill(numImages).map((_, i) => { 
                if(i < 5) {
                    const scale = 100 - ((i+1) * 10);
                    const animateScale = 100 - (i * 10);
                    const transformstyles = animateOut ? `translateY(-5px) scaleX(${animateScale}%)` : `translateY(0px) scaleX(${scale}%)`;
                    return (
                        <div key={i} className={`${cardStyles} ${animateOut ? 'transition-all duration-500' : ''}`} style={{transform: transformstyles}}>
                            <div className={shadowStyles}></div>
                        </div>
                    )
                } else {
                    return '';
                }
            })};
        </div>
    )
}

export default StackShadows;