import { StyleVariantProps } from "../../types";

interface ButtonProps {
	variant?: string;
	hoverVariant?: string;
	activeVariant?: string;
	size?: string;
	direction?: string;
	activeDirection?: string;
	disabled?: boolean;
	symbol?: React.ReactNode;
	onClick?: () => void;
}
const ReactionButton = ({ variant, hoverVariant, activeVariant, size, direction, activeDirection, disabled, symbol, onClick }: ButtonProps) => {

  const variants: StyleVariantProps = {
		color: {
			primary: `bg-teal hover:bg-buttonHoverGradient active:bg-nightSky active:scale-125 ${direction === 'left' ? 'active:rotate-[-45deg]' : direction === 'right' ? 'active:rotate-[45deg]' : ''}`,
			secondary: `bg-white hover:bg-buttonHoverGradient active:bg-nightSky`,
			tertiary: `bg-teal hover:bg-buttonHoverGradient`,
			default: 'bg-nightSky',
		},
        hover: {
            secondaryHover: `bg-white bg-buttonHoverGradient`,
        },
        active: {
            secondaryActive: `bg-nightSky scale-110`,
        },
        activeDirection: {
            left: `rotate-[-45deg]`,
            right: `rotate-[45deg]`,
        },
		size: {
			large: 'w-20 h-20',
			default: 'w-12 h-12',
		},
		disabled: {
			disabled: 'bg-buttonWhiteGradient',
			default: '',
		}
	}

    return (
        <button className={`inline-block cursor-pointer rounded-[100%] transition-all ease-in-out duration-300 active:scale-110 ${variant ? variants.color[variant] : variants.color.default} ${hoverVariant ? variants.hover[hoverVariant] : ''} ${activeVariant ? variants.active[activeVariant] : ''} ${size ? variants.size[size] : variants.size.default} ${activeDirection ? variants.activeDirection[activeDirection] : ''} ${disabled ? variants.disabled['disabled'] : variants.disabled.default}`} onClick={onClick}>
            <span className="flex items-center justify-center">
                {symbol}
            </span>
        </button>
    )
}

export default ReactionButton;