import ReconnectingWebSocket from 'reconnecting-websocket';
import { createContext, useState } from 'react'
import { joinChat } from '../api/dialogflow';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setCurrentFlow, setSessionId, updateChat, sendEvent, setCurrentPage } from '../redux/chatSlice';
import { formatChatMessage, formatQuizMessage } from '../helpers/chatFormatter';
import { addQuizContent, getQuizIndex, getUserCohort, setCurrentQuizIndex, setUserCohort } from '../redux/quizSlice';
import { ChatMessage } from '../types';

interface WebSocketContextProps {
    socket: any;
    sendMessage: (sessionId:string, message:any, type:string, silent?:boolean) => void;
    startChat: (sessionId:string) => void;
}
const WebSocketContext =  createContext<WebSocketContextProps | null>(null);
export { WebSocketContext }


interface WebSocketContextInterface {
    children: any;
}

const WebSocketWrapper = ({ children }: WebSocketContextInterface) => {
    let socket: any;
    let ws;
    let sessionId:string;
    const [chatStarted, setChatStarted] = useState<boolean>(false);
    const userCohort = useAppSelector(getUserCohort);
    const quizIndex = useAppSelector(getQuizIndex);

    const dispatch = useAppDispatch();

    const initChat = async() => {
        sessionId = await joinChat();
        dispatch(setSessionId(sessionId));
    }
    if(!chatStarted) {
        initChat();
    }


    const sendMessage = (sessionId:string, message:any, type: string, silent?:boolean) => {
        let payload = {};
        let chatText = '';
        let chatImage = null;
        
        switch (type) {
            case 'text':
                payload = {
                    sessionId,
                    type: 'text',
                    text: message.text
                }
                chatText = message.text;
                break;
            case 'answer':
                payload = {
                    sessionId,
                    type: type,
                    questionId: message.questionId,
                    answerId: message.answerId
                }
                chatText = message.text;
                chatImage = message.imageUrl;
                break;
            case 'event':
                payload = {
                    sessionId,
                    type: type,
                    text: message.text
                }
                chatText = message.text;
                break;
            
            default:
                break;
        }
                        
        socket.send(JSON.stringify(payload));
        
        if(type !== 'event' && !silent) {
            const outgoingMessage = {
                text: chatText,
                imageUrl: chatImage
            }
            const formattedMessage = formatChatMessage('outgoing', outgoingMessage);
            dispatch(updateChat(formattedMessage || []));
        }
    }

    const startChat = (sessionId:string) => {
        //TODO check ready state
        if(!chatStarted) {
            setChatStarted(true);
            const payload = {
                sessionId,
                type: 'event',
                text: 'start-onboarding'
            }
            socket.send(JSON.stringify(payload));
        }
    }

    const backendUrl = process.env.REACT_APP_BACKEND_URL ?`wss://${process.env.REACT_APP_BACKEND_URL}/ws` : null;
    if(backendUrl) {
        socket = new ReconnectingWebSocket(backendUrl);
    } else {
        throw new Error("NO VALID BACKEND URL");
    }

    socket.onerror = (error:any) => {
        //TODO Handle error?
    };

    socket.onmessage = (e:any) => {
        const { session, response, quizContent, page } = JSON.parse(e.data);
        
        dispatch(setCurrentFlow(session && session.currentFlow));
        dispatch(setCurrentPage(page || ''));

        let hasEventInResponse = false;
        
        if(response) {
            const formattedMessages = formatChatMessage('incoming', response );
            if(formattedMessages) {
                dispatch(updateChat(formattedMessages));
                const events = formattedMessages.filter((m:ChatMessage) => m.type === 'event');
                hasEventInResponse = events.length > 0;
            }
        }
            
        if(quizContent) {
            if(quizIndex >= quizContent.questions.length) {
                if(session.currentCohort && !userCohort) {
                    dispatch(setUserCohort({
                        category: session.category,
                        cohort: session.currentCohort
                    }));
                } else {
                    dispatch(sendEvent('determine-cohort'));
                }
            } else if (!hasEventInResponse) {
                const newQuizQuestion = quizContent.questions[quizIndex];
                const isCheckin = page && page.includes('checkin');
                if(newQuizQuestion) {
                    if(newQuizQuestion.type && newQuizQuestion.type === 'events') {                        
                        dispatch(sendEvent(newQuizQuestion.event));
                        dispatch(setCurrentQuizIndex(quizIndex + 1));
                    } else {
                        const formattedQuizQuestion = formatQuizMessage([newQuizQuestion]);
                        if(formattedQuizQuestion && !isCheckin) {
                            dispatch(updateChat(formattedQuizQuestion.messages));
                            if(formattedQuizQuestion.content)  dispatch(addQuizContent(formattedQuizQuestion.content));
                            dispatch(setCurrentQuizIndex(quizIndex + 1));
                        }
                    }
                }
            }
        }
    };

    ws = {
        socket: socket,
        sendMessage,
        startChat
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}

export default WebSocketWrapper;