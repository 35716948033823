import { Dimensions } from '../types';
import { ReactComponent as LogoIcon } from './../assets/icons/logo.svg';

interface WidgetButtonProps {
    onClick: () => void;
    isOpen: boolean;
    dimensions: Dimensions;
}
const WidgetButton = ({ onClick, isOpen, dimensions }: WidgetButtonProps) => {

  const showButtonWhenOpen = () => {
    const shouldShow = dimensions.width > 1024 && dimensions.height > 776;
    return !shouldShow;
  }

  return (
    <div 
        className={`cursor-pointer fixed bottom-6 right-4 lg:bottom-10 lg:right-10 flex justify-center items-center transition-all hover:scale-125 opacity-100 ${isOpen ? showButtonWhenOpen() ? 'opacity-0 pointer-events-none' : '' : ''}`}
        onClick={onClick}
        >
        <LogoIcon className=""/>
    </div>
  )
}

export default WidgetButton;