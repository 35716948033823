import { ChatMessage, QuizContent } from "../types";
import { v4 as uuidv4 } from 'uuid';


export const formatChatMessage = (direction: string, data: any) : ChatMessage[] | null => {
    if(!data) {
        return null;
    }

    if(direction === 'outgoing') {
        const message = {
            id: uuidv4(),
            direction: direction,
            type: data.imageUrl ? 'image' : 'text',
            message: data.text,
            imageUrl: data.imageUrl,
            position: 'single'
        }

        return [message];
    } else if(direction === 'incoming') {
        const messages = data.map((m:any, index: number) => {
            const position = index === 0 ? data.length === 1 ? 'single' : 'first' : undefined;
            let formattedMessage;
            if(m.message === 'text') {
                formattedMessage = {
                    id: uuidv4(),
                    direction: direction,
                    type: 'text',
                    message: m.text.text[0],
                    position: position
                }
            } else if (m.message === 'payload') {

                if(m.payload.options) {
                    //MULTI CHOICE
                    const list = m.payload.options;
                    const choices = list.map((item:any) => {
                        return {
                            id: uuidv4(),
                            label: typeof item === 'object' && item !== null ? item?.label : item,
                            event: typeof item === 'object' && item !== null ? item?.event : null,
                            asset: typeof item === 'object' && item !== null ? item?.asset : null
                        }
                    })

                    formattedMessage = {
                        id: uuidv4(),
                        direction: direction,
                        type: 'multichoice',
                        message: '',
                        choices: choices
                    };
                } else if (m.payload.event) {
                    formattedMessage = {
                        id: uuidv4(),
                        direction: direction,
                        type: 'event',
                        message: m.payload.event,
                        event: m.payload.event
                    };
                } else if (m.payload.result) {
                    formattedMessage = {
                        id: uuidv4(),
                        direction: direction,
                        type: 'event',
                        message: m.payload.result,
                        event: m.payload.result
                    };
                } else if (m.payload.asset) {
                    switch(m.payload.type) {
                        case 'cohort-hero':
                            formattedMessage = {
                                id: uuidv4(),
                                direction: direction,
                                type: 'cohort-hero',
                                message: '',
                                asset: m.payload.asset,
                            };
                            break;
                        default:
                            formattedMessage = {
                                id: uuidv4(),
                                direction: direction,
                                type: 'deals',
                                message: '',
                                asset: m.payload.asset,
                                link: m.payload.link
                            };
                            break;
                    }
                } else if (m.payload.type === 'sign-up') {
                    formattedMessage = {
                        id: uuidv4(),
                        direction: direction,
                        type: m.payload.type,
                        message:'',
                        dealsContent: m.payload.content,
                        assets: m.payload.assets
                    };
                } else {
                    return null;
                }
            }

            return formattedMessage;
        })
        return messages.filter((m : ChatMessage) => m !== null);
    }

    return null;
}   

export const formatQuizMessage = (data: any) : { messages: ChatMessage[], content: QuizContent[] } | null => {
    if(!data) return null;
    
    const messages = data?.map((question: any, index: number) => {
        let textMessages = [];
        if(question.content) {
            textMessages = question.content && question.content.map((message: string, textIndex: number) => {
                const position = (question.slug !== 'personal-style' && textIndex === 0) ? question.content.length === 1 ? 'single' : 'first' : undefined;

                return {
                    id: uuidv4(),
                    direction: 'incoming',
                    type: 'text',
                    message: message,
                    quizIndex: index,
                    quizSlug: question.slug,
                    position: position
                };
            });
        }
        if(question.choices) {
            const choices = question.choices && question.choices.map((choice:any) => {
                return (
                    {
                        id: uuidv4(),
                        label: choice.displayTitle,
                        slug: choice.slug,
                        quizSlug: question.slug,
                        customResponse: choice.customBotResponse,
                        cohortId: choice.cohortId,
                        asset: choice.asset || null
                    }
                )
            })

            //TODO get event trigger from backend
            let promptQuestion;
            if(question.type === 'games') {
                 promptQuestion = {
                    id: uuidv4(),
                    direction: 'incoming',
                    type: 'event',
                    message: '',
                    quizIndex: index,
                    quizSlug: question.slug,
                    event: question.slug
                }
            } else {
                //TODO get slider type from backend
                const isSlider = question.slug === 'deals-preference';
                promptQuestion = {
                    id: uuidv4(),
                    direction: 'incoming',
                    type: isSlider ? 'slider' : question.multipleResponses ? 'multiselect' : 'multichoice',
                    message: '',
                    choices: choices,
                    quizIndex: index,
                    quizSlug: question.slug,
                }
            }

            return [...textMessages, promptQuestion];
        } else {
            return {
                id: uuidv4(),
                direction: 'incoming',
                type: 'event',
                message: '',
                quizIndex: index,
                quizSlug: question.slug,
                event: question.event
            }
        }
    })

    let content : QuizContent[] = [];
    data?.forEach((question : any) => {
        if(question.type === 'games') {
            const quizContent = {
                slug: question.slug,
                choices: question.choices
            }
            content.push(quizContent);
        }
    });

    return {
        messages: messages.flat(),
        content: content
    };
}