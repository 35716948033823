import { useEffect, useState } from "react";
import { SliderMarkProps } from "../../types";
import { ReactComponent as SliderMarkIcon} from './../../assets/icons/slider-mark.svg';
import moneyEmoji from './../../assets/icons/money-bag.svg';
import shrugEmoji from './../../assets/icons/shrug.svg';
import diamondEmoji from './../../assets/icons/diamond.svg';
import Button from "../common/Button";
import { ReactComponent as ArrowRightIcon } from './../../assets/icons/arrow-right.svg';


interface SliderProps {
    onChoice: (message: SliderMarkProps) => void;
}

const ChatSlider = ({onChoice} : SliderProps) => {

    const [sliderValue, setSliderValue] = useState<string>('');
    const [marks, setMarks] = useState<Array<any> | null>(null);
    const [chosenMessage, setChosenMessage] = useState<any | null>(null);

    const handleChange = (value: any) => {
        setSliderValue(value);

        const chosen = marks?.find(m => m.value === value);
        setChosenMessage(chosen);
    }

    useEffect(() => {
        const defaultMarks : SliderMarkProps[] = [
            {
              value: '1',
              label: 'Deals',
              imageUrl: moneyEmoji
            },
            {
              value: '2',
              label: 'Indifferent',
              imageUrl: shrugEmoji
            },
            {
              value: '3',
              label: 'Drops',
              imageUrl: diamondEmoji
            }
        ];

        setSliderValue('2');
        setMarks(defaultMarks);
    }, [])
    
    const sliderStyles = 'slider absolute left-0 right-0 bottom-0 px-6';

    return (

        <div className="pt-5 pb-10 tall:pb-20">
            <div className="px-6 pt-[140px] relative">
                <div className="relative px-4 ">
                    <div className="w-full h-1 bg-yellow flex justify-between items-center relative">
                        {sliderValue && marks && marks.map((mark, index) => (
                            <div key={`mark-${index}`} className="relative w-4 h-4 bg-teal rounded-full p-[2px] cursor-pointer " onClick={() => handleChange(mark.value)}>
                                <div className="relative w-3 h-3 bg-nightSky rounded-full border-2 border-white">
                                    <div className="absolute top-[-30px] left-[50%] translate-x-[-50%] translate-y-[-100%]">
                                        <div className="relative">
                                            <SliderMarkIcon className={`w-[70px] h-[75px] transition-all origin-bottom	${sliderValue === mark.value ? 'scale-[120%]' : ''}`} />
                                            <span className={`w-full h-full absolute top-0 left-0 bg-no-repeat bg-center transition-all origin-bottom	${sliderValue === mark.value ? 'scale-[120%]' : ''}`} style={{backgroundImage: `url(${mark.imageUrl})`}}></span>
                                        </div>
                                        <span className="text-sm font-roobertSemiBold text-nightSky">{mark.label}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <input type="range" min="1" max="3" className={sliderStyles} value={sliderValue} onChange={(event) => handleChange(event.target.value)}></input>
            </div>
            <div className="mt-10">
                <Button label="Continue" variant="primary" disabled={!chosenMessage} symbolAfter={<ArrowRightIcon className="ml-2 mt-1 fill-white" />} onClick={() => onChoice(chosenMessage)} />
            </div>
        </div>
    )
}

export default ChatSlider;