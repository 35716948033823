import { useEffect, useState } from "react";
import Slider from "react-slick";

import { MultiChoiceMessage } from "../../types";
import Button from "../common/Button";
import ImageWithGradient from "../common/ImageWithGradient";
import SwipeArrow from "../common/SwipeArrow";

interface MultiChoiceProps {
    messages: MultiChoiceMessage[];
    onChoice: (message: MultiChoiceMessage) => void;
}
const MultiChoice = ({messages, onChoice} : MultiChoiceProps) => {
    const [slider, setSlider] = useState<Slider | null>(null);
    const hasAssets = messages[0].asset ? true : false;
    const [flex, setFlex] = useState<boolean>(false);

    const handlePrev = () => {
        if(slider) slider.slickPrev();
    }
    const handleNext = () => {
        if(slider) slider.slickNext();
    }

    const sliderSettings = {
        variableWidth: true,
        slidesToScroll: 2,
        arrows: false,
        draggable: false
    }
    
    useEffect(() => {
      if(messages) {
          setFlex(messages.length <= 2 && messages[0].label.length < 20);
      }
    }, [messages])
    
    return (
        <div className={`pt-5 pb-10 tall:pb-20 ${flex ? 'flex px-4' : ''}`}>
            {hasAssets ? (
                <div className={`relative`}>
                    <Slider ref={c => (setSlider(c))} {...sliderSettings}>
                        {messages.map((message: MultiChoiceMessage) => (
                            <div key={`multi-${message.id}`} className="inline-block m-1">
                                {message.asset && (
                                    <button onClick={() => onChoice(message)}   >
                                        <ImageWithGradient width="w-[135px]" height="h-[194px]" imageUrl={message.asset.file.url} label={message.label} />  
                                    </button>
                                )}
                            </div>
                        ))}
                    </Slider>
                    <div className="absolute left-2 top-[50%] -translate-y-[50%]">
                        <SwipeArrow direction="left" onClick={() => handlePrev()} />
                    </div>
                    <div className="absolute right-2 top-[50%] -translate-y-[50%]">
                        <SwipeArrow direction="right" onClick={() => handleNext()} />
                    </div>
                </div>
            ) : (
                messages.map((message: MultiChoiceMessage) => (
                    <div key={`multi-${message.id}`} className={`inline-block m-1 ${flex ? 'flex-1' : ''}`}>
                        <Button label={message.label} variant="toggleOff" size="small" fillWidth={flex} onClick={() => onChoice(message)} />
                    </div>
                )
            ))}
        </div>
    )
}

export default MultiChoice;