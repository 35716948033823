import { useContext, useEffect, useState } from "react";
import { useSwipeable } from 'react-swipeable';
import { WebSocketContext } from "../../context/WebSocketContext";
import { ThemeContext } from './../../context/ThemeContext';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setShowOverlay } from "../../redux/appSlice";
import { getQuizContent, quizDone } from "../../redux/quizSlice";
import { getSessionId } from "../../redux/chatSlice";
import { QuizItem } from "../../types";
import Button from "../common/Button";
import ReactionButton from "../common/ReactionButton";
import StackShadows from "../common/StackShadows";
import QuizProgress from "./QuizProgress";
import { ReactComponent as ArrowRightIcon } from './../../assets/icons/arrow-right.svg';
import { ReactComponent as HeartIcon } from './../../assets/icons/heart.svg';
import { ReactComponent as ThumbsDownIcon } from './../../assets/icons/thumbs-down.svg';
import doneImg from './../../assets/images/quiz-done.jpg';
import { quizContentWrapper, quizFooterWrapper, quizHeadlineStyles, quizHeadlineWrapper, quizLabelStyles, quizSubheaderStyles, quizWrapper } from "../../styles";
import beyonceImg from "../../assets/images/beyonce-test.png";
import mixpanel from "mixpanel-browser";


interface LoveOrHateProps {
    id: string;
    completeQuiz: boolean;
  }
  const LoveOrHate = ({ id, completeQuiz }: LoveOrHateProps) => {
    const [isIntro, setIsIntro] = useState<boolean>(true);
    const [animateInLabel, setAnimateInLabel] = useState<boolean>(false);
    const [animateOutIntro, setAnimateOutIntro] = useState<boolean>(false);
    const [animateInContent, setAnimateInContent] = useState<boolean>(false);
    const [animateInResults, setAnimateInResults] = useState<boolean>(false);
    const [content, setContent] = useState<any | null>(null);
    const [likedOrDisliked, setLikedOrDisliked] = useState<string>('');
    const [hasResults, setHasResults] = useState<boolean>(false);
    const [currentRound, setCurrentRound] = useState<number>(0);
    const [currentImage, setCurrentImage] = useState<QuizItem | null>(null);
    const [nextImage, setNextImage] = useState<any | null>(null);
    const [progress, setProgress] = useState<number>(0);
    const dispatch = useAppDispatch();
    const theme = useContext(ThemeContext);
    const quizContent = useAppSelector(getQuizContent);
    const [liked, setLiked] = useState<Array<QuizItem>>([]);

    const sessionId = useAppSelector(getSessionId);
    const ws = useContext(WebSocketContext);

    const handleClick = (vote: string) => {
      mixpanel.track('Dope or Nope', {
        vote,
        currentRound,
        currentImage
      })
      
      setLikedOrDisliked(vote);
      if(vote === 'liked' && currentImage) {
        setLiked([...liked, currentImage]);
      }
      setAnimateInLabel(false);

      const nextRound = currentRound + 1;
      if(nextImage) {
        setTimeout(() => {
          setLikedOrDisliked('');
          if(nextRound < content.choices.length) {
            setCurrentImage(nextImage);
          }
          setCurrentRound(nextRound);
        }, 1000);
      } else {
        //finish up   
        mixpanel.track('Dope or Nope End Screen', {
          choices: content.choices
        })     
        setTimeout(() => {
          setAnimateInContent(false);
        }, 1000);
        setTimeout(() => {
          setHasResults(true);
          setAnimateInResults(true);
        }, 1500);
      }
    }

    const handleComplete = () => {
      const slugs = liked.map(item => item.slug);
      mixpanel.track('Dope or Nope Completed', {
        choices: content.choices,
        liked,
        likedTitles: liked.map(l => l.title),
        slugs
      })

      const message = {
        questionId: id,
        answerId: slugs,
        text: ''
      }
      if(ws && sessionId) {
        ws.sendMessage(sessionId, message, 'answer', true);
      }

      dispatch(quizDone('dope-or-nope'));
      dispatch(setShowOverlay(false))
    }

    const swipeHandler = useSwipeable({
      onSwiped: (eventData) => {
        switch (eventData.dir) {
          case 'Left':
            if(likedOrDisliked !== '') return;
            handleClick('disliked')
            break;
          case 'Right':
            if(likedOrDisliked !== '') return;
            handleClick('liked')
            break;
        }
      }
    });


    useEffect(() => {
      if(completeQuiz) {
        handleComplete();
      }
    // eslint-disable-next-line
    }, [completeQuiz])

    useEffect(() => {
      if(content) {
        setCurrentImage(content.choices[0]);
        setNextImage(content.choices[1]);
        setProgress(Math.round(100 / content.choices.length));
      }
    }, [content])



    useEffect(() => {
      if(content && currentRound > 0) {

        const percent = (100 / content.choices.length) * (currentRound + 1);
        setProgress(Math.round(percent));

        if(currentRound < content.choices.length - 1) {
          setNextImage(content.choices[currentRound + 1]);
        } else {
          setNextImage(null);
        }
      }
    }, [currentRound, content])

    useEffect(() => {
      if(currentImage) {
        setAnimateInLabel(true);
      }
    }, [currentImage])

    useEffect(() => {
      if(theme && isIntro) {
        theme.setBlueBg(true);
      }
    }, [isIntro, theme])

    useEffect(() => {
      let content = quizContent.filter(item => item.slug === id);
      if(content.length <= 0) {
        content = [
          {
            slug: 'dope-nope',
            choices: [
              {
                slug: 'one',
                title: 'One',
                asset:  {
                  file: {
                    url: beyonceImg
                  }
                }
              },
              {
                slug: 'two',
                title: 'Two',
                asset:  {
                  file: {
                    url: beyonceImg
                  }
                }
              },
              {
                slug: 'three',
                title: 'Three',
                asset:  {
                  file: {
                    url: beyonceImg
                  }
                }
              }
            ]
          }
        ]
      }

      const copyContent = {
        intro: {
          headline: 'Let’s play <br/>Dope or Nope',
          paragraph: 'I told you this was<br />like Tinder!'
        },
        play: {
          headline: 'Dope or Nope',
          subheader: 'Choose the celebrity style you love most',
        }
      }

      setContent({...copyContent, ...content[0]});
    }, [id, quizContent])

    const animateOutLeft = `transition-all duration-1000 translate-x-[-200%] rotate-[-45deg] translate-y-[20%]`;
    const animateOutRight = `transition-all duration-1000 translate-x-[200%] rotate-[45deg] translate-y-[20%]`;
    const animateInText = `duration-200 opacity-100`;
    const animateOutText = `transition-all duration-200 opacity-0 ${likedOrDisliked === 'disliked' ? 'translate-x-[30%] rotate-[15deg]' : 'translate-x-[-30%] rotate-[-15deg]'} translate-y-[30%]`;

    return (
      content && (
        <div className="this-or-that relative w-full h-full m-0">
          {!hasResults ?
            isIntro ? (
             <div className={`${quizWrapper} pt-[60px] transition-opacity duration-300 ${animateOutIntro ? 'opacity-0' : 'opacity-100'}`}>
              <div className={`${quizHeadlineWrapper} px-4`}>
                <h2 className={`${quizHeadlineStyles} text-white`}><span dangerouslySetInnerHTML={{__html:content.intro.headline}}></span></h2>
              </div>
              <div className={`${quizContentWrapper} flex-col`}>
                <div className="flex">
                  <div className="px-6 flex flex-col justify-between">
                    <ThumbsDownIcon className={`fill-white w-[78px] h-[78px]`} />
                    {/* <p className={`text-nightSkyLight text-2xl mt-3`}>Nope</p> */}
                  </div>
                  <div className="px-6 flex flex-col justify-between">
                    <HeartIcon className={`fill-white w-[91px] h-[84px]`} />
                    {/* <p className={`text-nightSkyLight text-2xl mt-3`}>Dope</p> */}
                  </div>
                </div>
                <p className={`text-white text-2xl w-[85%] mt-10`} dangerouslySetInnerHTML={{__html: content.intro.paragraph}}></p>
              </div>
              <div className={`${quizFooterWrapper} h-[140px] tall:h-[160px] tallest:h-[200px]`}>
              <Button onClick={() => {
                setAnimateOutIntro(true);
                mixpanel.track("Start Dope or Nope")
                setTimeout(() => {
                  setIsIntro(false);
                  theme?.setBlueBg(false);
                  setAnimateInContent(true);
                }, 300);
              }} label="Let’s do this!" size="large" variant="tertiary" symbolAfter={<ArrowRightIcon className="ml-2 mt-1 fill-white" style={{width: 16, height: 20}} />} />
              </div>
            </div>
           ) : (
            <div className={`${quizWrapper} pt-[20px] transition-opacity duration-300 ${animateInContent ? 'opacity-100' : 'opacity-0'}`}>
              <div className={`${quizHeadlineWrapper}`}>
                <QuizProgress progress={progress} />

                <h2 className={`${quizHeadlineStyles} text-nightSky`}>{content.play.headline}</h2>
              </div>
              <div className={`${quizContentWrapper}`}>
									<div className="h-full w-full px-[60px] grid grid-rows-[auto_50px]" {...swipeHandler}>
                    <div className={`w-[190px] tallMobile:w-[252px] tall:w-[190px] tallest:w-[252px] h-full m-auto relative pt-6`}>
                      <div className={`aspect-w-[186] aspect-h-[255] tallest:aspect-w-[252] tallest:aspect-h-[345] relative`}>
                        <div>
                          {nextImage && (
                            <div className={`w-full h-full rounded-[8px] overflow-hidden bg-white bg-no-repeat bg-cover bg-bottom absolute opacity-0 scale-90 translate-y-[6px] origin-bottom ${likedOrDisliked !== '' ? 'transition-all duration-1000 opacity-[100%] scale-[100%] translate-y-[-0px]' : ''}`} style={{backgroundImage: `url(${nextImage.asset?.file?.url})`}}></div>
                          )}
                          {currentImage && (
                            <div className={`w-full h-full rounded-[8px] overflow-hidden bg-white bg-no-repeat bg-cover bg-bottom absolute ${likedOrDisliked === 'liked' ? animateOutRight : ''} ${likedOrDisliked === 'disliked' ? animateOutLeft : ''}`} style={{backgroundImage: `url(${currentImage.asset?.file?.url})`}}></div>
                          )}
                        </div>
                      </div>
                      <div className={`w-full h-[17px] mt-[-1px]`} >
                        <StackShadows update={likedOrDisliked !== ''} numImages={content.choices.length - (currentRound + 1)} />
                      </div>

                    </div>
                    {currentImage && (
										  <p className={`mx-auto ${quizLabelStyles} ${(likedOrDisliked !== '') ? animateOutText : ''}  ${(animateInLabel && likedOrDisliked === '') ? animateInText : ''} `}>{currentImage.title}</p>
                    )}

									</div>
              </div>
              <div className={`${quizFooterWrapper} h-[170px] tall:h-[180px] tallest:h-[200px] shrink-0 flex justify-center pt-[28px]`}>
                <div>
                  <div className="mx-4 inline-block">
                    <ReactionButton variant={`primary`} size='large' disabled={likedOrDisliked === 'liked'} direction="left" symbol={
                      <ThumbsDownIcon className={`fill-white w-[34px] h-[34px]`} />
                    } onClick={() => {
                      if(likedOrDisliked !== '') return;
                      handleClick('disliked')
                    }} />
                  </div>
                  <div className="mx-4 inline-block">
                    <ReactionButton variant={`primary`} size='large' disabled={likedOrDisliked === 'disliked'} direction="right" symbol={
                      <HeartIcon className={`fill-white w-[36px] h-[34px]`} />
                    } onClick={() => {
                      if(likedOrDisliked !== '') return;
                      handleClick('liked')
                    }} />
                  </div>
                </div>
              </div>
            </div>
           ):
          (
            <div className={`${quizWrapper}  bg-no-repeat bg-[length:auto_100%] bg-center pt-[60px] bg-[#dfded9] transition-opacity duration-300 ${animateInResults ? 'opacity-100' : 'opacity-0'}`} style={{backgroundImage: `url(${doneImg})`}}>
              <div className={`${quizHeadlineWrapper}`}>
								<h2 className={`${quizHeadlineStyles} text-nightSky`}>{content.play.headline}</h2>
                <p className={`${quizSubheaderStyles} max-w-[200px] mx-auto mt-2 text-nightSky`}>Thank you for playing!</p>
              </div>
              <div className={`${quizContentWrapper}`}>
              </div>
              <div className={`${quizFooterWrapper} flex items-end justify-center pb-[60px]`}>
                <Button onClick={handleComplete} label="Continue" size="large" symbolAfter={<ArrowRightIcon className="ml-2 mt-1 fill-white" style={{width: 16, height: 20}} />} />
              </div>
            </div>
          )}

        </div>
      )
    );
  };

  export default LoveOrHate;
