import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ChatMessage } from '../types';

export interface ChatState {
  sessionId: string | null;
  messages: any[];
  formattedMessages: any[];
  quizMessages: any[];
  currentFlow: string;
  currentPage: string;
  sendEvent: string | null;
}

const initialState: ChatState = {
	sessionId: null,
  messages: [],
  formattedMessages: [],
  quizMessages: [],
  currentFlow: '',
  currentPage: '',
  sendEvent: null
};


export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setSessionId: (state, action: PayloadAction<string>) => {
        state.sessionId = action.payload;
    },
    updateChat:  (state, action: PayloadAction<ChatMessage[]>) => {
      state.messages = [
        ...state.messages,
        ...action.payload
      ]
    },
    setFormattedChat:  (state, action: PayloadAction<any>) => {
      state.formattedMessages = [
        ...state.formattedMessages,
        ...action.payload
      ]
    },
    setQuizMessages:  (state, action: PayloadAction<any>) => {
      state.quizMessages = [
        ...state.quizMessages,
        ...action.payload
      ]
    },
    resetChat:  (state) => {
      state.messages = [];
    },
    setCurrentFlow:  (state, action: PayloadAction<string>) => {
      state.currentFlow = action.payload;
    },
    setCurrentPage:  (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload;
    },
    sendEvent: (state, action: PayloadAction<string | null>) => {
      state.sendEvent = action.payload;
    }
  },
});

export const { setSessionId, updateChat, setFormattedChat, setQuizMessages, resetChat, setCurrentFlow, setCurrentPage, sendEvent } = chatSlice.actions;

export const getSessionId = (state: RootState) => state.chat.sessionId;
export const getMessages = (state: RootState) => state.chat.messages;
export const getFormattedMessages = (state: RootState) => state.chat.formattedMessages;
export const getQuizMessages = (state: RootState) => state.chat.quizMessages;
export const getCurrentFlow = (state: RootState) => state.chat.currentFlow;
export const getCurrentPage = (state: RootState) => state.chat.currentPage;
export const getSendEvent = (state: RootState) => state.chat.sendEvent;

export default chatSlice.reducer;
