//global

//chat

//quiz
export const quizButtonStyles = "bg-sky-400 hover:bg-sky-500 text-white rounded-full py-2 px-4";
export const quizButtonStylesSelected = "bg-sky-600 hover:bg-sky-700 text-white rounded-full py-2 px-4";
export const largeButtonStyles = "bg-sky-600 hover:bg-sky-700 text-white font-bold rounded-full py-4 px-8";
export const quizHeadlineStyles = "text-[40px] leading-[46px] font-roobertRegular";
export const quizSubheaderStyles = "text-[18px] leading-[24px] font-roobertMedium";
export const quizLabelStyles = "text-[18px] text-nightSky font-roobertMedium mt-3";
export const quizWrapper = "h-full flex flex-col";
export const quizHeadlineWrapper = "mt-[50px] h-[120px] grow-0";
export const quizContentWrapper = "grow flex justify-center items-center";
export const quizFooterWrapper = "grow-0 h-[200px] tall:h-[240px]";
export const eyebrowStyles = "font-roobertBold text-[16px] leading-[26px] text-blueLight uppercase";
