import { eyebrowStyles, quizHeadlineStyles } from "../../styles";
import { ChatMessage } from "../../types";
import placeholderImg from './../../assets/images/shoe-seeker.png';

interface StyleDNAProps {
	data: ChatMessage | null;
}

const StyleDNA = ({data} : StyleDNAProps) => {

  return (

    data && (

      <div className="bg-nightSky">
        <div className="mb-8">
            <h3 className={`${eyebrowStyles}`}>Your BFF DNA:</h3>
            <h3 className={`${quizHeadlineStyles}`}>{data.asset?.title}</h3>
        </div>
        <div className="w-full h-[360px] bg-cover bg-center rounded mb-6" style={{backgroundImage: `url(${(data.asset && data.asset.file) ? data.asset.file.url : placeholderImg})`}}></div>
      </div>
    )
  )
}

export default StyleDNA;