import { useContext, useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { getProfilePlaceholderImage } from './ProfileImages';
import { useAppDispatch } from '../../hooks';
import { showEndCap } from '../../redux/appSlice';
import { ThemeContext } from './../../context/ThemeContext';
import { UserCohort } from '../../types';
import Button from '../common/Button';

interface ProfilePlaceholderProps {
	cohort: UserCohort | null;
}

const ProfilePlaceholder = ({ cohort }: ProfilePlaceholderProps) => {
  const theme = useContext(ThemeContext);
  const profilePlaceholderImg = getProfilePlaceholderImage(cohort);
  const [showFinishButton, setShowFinishButton] = useState(false);
  const dispatch = useAppDispatch();

  const handleFinishDemoClick = () => {
    mixpanel.track('Finish demo');
    dispatch(showEndCap());
  }

  useEffect(() => {
    if(theme) {
      theme.setBlueBg(true);
    }
  }, [theme]);

  useEffect(() => {
    mixpanel.track('Profile', {
      category: cohort?.category,
      cohort: cohort?.cohort,
    });

    setTimeout(() => {
      setShowFinishButton(true)
    }, 5000);
  });

  return (
    <div className="profile h-min w-full pt-20 m-0">
      <div className={`sticky top-1/2 right-1 pr-4 text-right transition-opacity ease-in duration-200 ${ showFinishButton ? 'opacity-100' : 'opacity-0 pointer-events-none' }`}>
        <Button label="Finish Demo &rarr;" variant="finish" onClick={handleFinishDemoClick} />
      </div>
      <img src={profilePlaceholderImg} alt="" />
    </div>
  );
}

export default ProfilePlaceholder;
