import axios from 'axios';

export const joinChat = async (): Promise<any> => {
  let sessionId;

  return new Promise((resolve, reject) => {
    axios
      .post(`https://${process.env.REACT_APP_BACKEND_URL}/join`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => res.data)
      .then(data => {
        sessionId = data.sessionId;
        localStorage.setItem('sessionId', data.sessionId);
        resolve(sessionId);
      })
      .catch((error) => reject(error));
  });
};
