import { StyleVariantProps } from "../../types";

interface ButtonProps {
	label: string;
	variant?: string;
	size?: string;
	disabled?: boolean;
	fillWidth?: boolean;
	symbolAfter?: React.ReactNode;
	symbolBefore?: React.ReactNode;
	onClick?: () => void;
}
const Button = ({ label, variant, size, disabled, fillWidth, symbolAfter, symbolBefore, onClick }: ButtonProps) => {

  const variants: StyleVariantProps = {
		color: {
			primary: 'bg-nightSky hover:bg-buttonHoverGradient',
			secondary: 'bg-grey hover:bg-buttonHoverGradient',
			tertiary: `bg-teal hover:bg-buttonHoverGradient`,
			textLink: 'bg-transparent',
			toggleOff: 'bg-transparent shadow-innerBorder2Yellow hover:shadow-innerBorder4Yellow box-border hover:box-border',
			toggleOn: 'bg-yellow',
			default: 'bg-nightSky',
			finish: 'bg-blueLight',

		},
		disabled: {
			disabled: 'opacity-20'
		},
		size: {
			large: 'pt-[14px] pb-[18px] px-[26px] rounded-[8px]',
			small: 'pt-[8px] pb-[12px] px-[26px]  rounded-[6px] h-50',
			default: 'pt-[12px] pb-[16px] px-[26px] rounded-[8px]',
		},
		label: {
			primary: 'text-white font-roobertMedium',
			secondary: 'text-black font-roobertMedium',
			tertiary: 'text-white font-roobertMedium',
			textLink: 'text-darkGrey underline font-roobertMedium',
			toggleOff: 'text-white font-roobertSemiBold',
			toggleOn: 'text-nightSky font-roobertSemiBold',
			default: 'text-white font-roobertMedium',
			finish: 'text-nightSky font-roobertMedium',
		},
		labelSize: {
			large: 'text-[22px] leading-[26px]',
			small: 'text-[14px] leading-[26px]',
			default: 'text-lg',
		}
	}

  return (
		<button className={`inline-block ${fillWidth ? 'w-[100%]' : ''} cursor-pointer transition-all duration-300 ease-in-out ${( variant ? variants.color[variant] : variants.color.default)}  ${( size ? variants.size[size] : variants.size.default)} ${disabled ? variants.disabled.disabled : ''} ${ variant === 'finish' && 'drop-shadow-[0_6px_5px_rgba(0,0,0,0.25)]'}`} onClick={onClick}>
			<span className="flex items-center justify-center">
				{symbolBefore}
				<span className={`${( variant ? variants.label[variant] : variants.label.default)}   ${( size ? variants.labelSize[size] : variants.labelSize.default)}`}>{label}</span>
				{symbolAfter}
			</span>
		</button>
  )
}

export default Button;