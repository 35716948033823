import { ReactComponent as ArrowLeftIcon } from './../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from './../../assets/icons/arrow-right.svg';

interface SwipeArrowProps {
	direction: string;
    disabled?: boolean;
	onClick?: () => void;
}
const SwipeArrow = ({direction, disabled, onClick }: SwipeArrowProps) => {

    const arrowStyles = `${disabled ? 'fill-black' : 'fill-nightSky'}`;

    return (
        <button className={`inline-block cursor-pointer w-[40px] h-[40px] bg-white rounded-[100%] `} onClick={onClick}>
            <span className="flex items-center justify-center">
                {direction === 'left' ? (
                    <ArrowLeftIcon className={arrowStyles} />
                ) : (
                    <ArrowRightIcon className={arrowStyles} />
                )}
            </span>
        </button>
    )
}

export default SwipeArrow;