import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks';
import { showProfile } from '../../redux/appSlice';
import { UserCohort } from '../../types';

interface ProfilePlaceholderProps {
	cohort: UserCohort | null;
}

const Registration = ({ cohort }: ProfilePlaceholderProps) => {
  const name = cohort?.category === 'mens' ? 'Ethan Smith' : 'Evelyn Smith'
  const dispatch = useAppDispatch();

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    mixpanel.track('Submit registration');
    dispatch(showProfile());
  }

  useEffect(() => {
    mixpanel.track('Registration');
  }, []);

  return (
    <div className="registration absolute top-20 bg-white m-0">
      <div className="h-600 flex flex-col justify-between items-center m-0 p-2 text-nightSky">
        <h2 className="text-[40px] tracking-tight mx-14 mb-4" style={{ lineHeight: "46px" }}>Create Your BFF Account</h2>
        <p className="mx-10 mb-8">Get $50 instantly from one of your favorite merchants</p>
        <form className="w-full p-2" onSubmit={handleSubmit}>
          <input type="text" className="block w-full border-2 border-nightSky/20 rounded-md p-4 mb-2" placeholder="Name" value={name} readOnly />
          <input type="text" className="block w-full border-2 border-nightSky/20 rounded-md p-4 mb-2" placeholder="Phone" value="504-652-1234" readOnly />
          <input type="text" className="block w-full border-2 border-nightSky/20 rounded-md p-4 mb-2" placeholder="Email" value="esmith86@gmail.com" readOnly />
          <div className="relative w-full block">
            <input type="password" className="block w-full border-2 border-nightSky/20 rounded-md p-4 mb-10" placeholder="Password" value="password" readOnly />
            <label className="absolute top-3 right-4 py-1 text-nightSky/40 rounded px-2 cursor-pointer">Show</label>
          </div>
          <button className="text-[22px] text-white bg-nightSky rounded-lg px-7 pt-3 pb-4 mb-4">Let’s do this! &rarr;</button>
        </form>
        <p>
          <a className="text-sm underline" target="_blank" rel="noreferrer noopener">Terms &amp; Conditions</a> { /* eslint-disable-line jsx-a11y/anchor-is-valid */ }
        </p>
      </div>
    </div>
  );
}

export default Registration;
