import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Dimensions } from '../types';

export interface AppState {
  view: string;
  showOverlay: boolean;
  allowOverlayScroll: boolean;
  quizId: string;
  windowDimensions: Dimensions | null;
}

const initialState: AppState = {
	view: 'quiz',
  showOverlay: false,
  allowOverlayScroll: false,
  quizId: '',
  windowDimensions: null
};


export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    goToView: (state, action: PayloadAction<string>) => {
			state.view = action.payload;
    },
    setShowOverlay: (state, action: PayloadAction<boolean>) => {
			state.showOverlay = action.payload;
    },
    startStyleBattle: (state, action: PayloadAction<string>) => {
			state.quizId = action.payload;
			state.view = 'battle';
      state.allowOverlayScroll = false;
			state.showOverlay = true;
    },
    startLoveOrHate: (state, action: PayloadAction<string>) => {
			state.quizId = action.payload;
			state.view = 'lovehate';
      state.allowOverlayScroll = false;
			state.showOverlay = true;
    },
		showRegistration: (state) => {
			state.view = 'registration';
      state.allowOverlayScroll = true;
			state.showOverlay = true;
		},
    showProfile: (state) => {
			state.view = 'profile';
      state.allowOverlayScroll = true;
			state.showOverlay = true;
		},
    showEndCap: (state) => {
			state.view = 'end-cap';
      state.allowOverlayScroll = true;
			state.showOverlay = true;
		},
    setWindowDimensions: (state,  action: PayloadAction<Dimensions>) => {
			state.windowDimensions = action.payload;
		},
  },
});

export const { goToView, setShowOverlay, startStyleBattle, startLoveOrHate, showRegistration, showProfile, showEndCap, setWindowDimensions } = appSlice.actions;

export const getView = (state: RootState) => state.app.view;
export const getShowOverlay = (state: RootState) => state.app.showOverlay;
export const getAllowOverlayScroll = (state: RootState) => state.app.allowOverlayScroll;
export const getQuizId = (state: RootState) => state.app.quizId;
export const getWindowDimensions = (state: RootState) => state.app.windowDimensions;


export default appSlice.reducer;
