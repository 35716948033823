
interface ResultsMessageProps {
	message: string;
}

const ResultsMessage = ({message} : ResultsMessageProps) => {

  return (
    <div className="bg-nightSky">
        <div className="text-left">
            <p>{message}</p>
        </div>
    </div>
  )
}

export default ResultsMessage;