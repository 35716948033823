import  React  from  "react";

interface ThemeContextProps {
    blueBg: boolean;
    setBlueBg: (value: boolean) => void;
}

const  ThemeContext = React.createContext<ThemeContextProps | null>(null);
export { ThemeContext }


interface ThemeContextInterface {
    children: any;
}

const ThemeProvider = ({ children }: ThemeContextInterface)  =>  {

    const [blueBg, isSetBlueBg] = React.useState(false);

    const setBlueBg = (value: boolean) => {
        isSetBlueBg(value);
    } 
    
    const theme : ThemeContextProps = {
        blueBg,
        setBlueBg
    }

    return  (
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    );
};
export default ThemeProvider;