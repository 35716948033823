import { UserCohort } from '../../types';
import mensGlamSquadImg from './../../assets/images/profile-placeholders/mens-glam-squad.jpg';
import womensGlamSquadImg from './../../assets/images/profile-placeholders/womens-glam-squad.jpg';
import mensGymRatImg from './../../assets/images/profile-placeholders/mens-gym-rat.jpg';
import womensGymRatImg from './../../assets/images/profile-placeholders/womens-gym-rat.jpg';
import mensSneakerHeadImg from './../../assets/images/profile-placeholders/mens-sneaker-head.jpg';
import womensSneakerHeadImg from './../../assets/images/profile-placeholders/womens-sneaker-head.jpg';

export const getProfilePlaceholderImage = (cohort: UserCohort | null) => {
  switch(cohort?.category) {
    case 'mens':
      switch(cohort?.cohort) {
        case 'glam-squad':
          return mensGlamSquadImg;
        case 'gym-rat':
          return mensGymRatImg;
        case 'sneaker-head':
          return mensSneakerHeadImg;
      }
      break;
    case 'womens':
      switch(cohort?.cohort) {
        case 'glam-squad':
          return womensGlamSquadImg;
        case 'gym-rat':
          return womensGymRatImg;
        case 'sneaker-head':
          return womensSneakerHeadImg;
        default:
          return womensGlamSquadImg;
      }
    default:
      return womensGlamSquadImg;
  }
}
