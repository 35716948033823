import { useState } from "react";
import { MultiChoiceMessage } from "../../types";
import Button from "../common/Button";
import { ReactComponent as ArrowRightIcon } from './../../assets/icons/arrow-right.svg';


interface MultiSelectProps {
    messages: MultiChoiceMessage[];
    onChoice: (messages: MultiChoiceMessage[]) => void;
}

const MultiSelect = ({messages, onChoice} : MultiSelectProps) => {

    const [chosen, setChosen] = useState<Array<MultiChoiceMessage>>([]);

    const handleClick = (message : MultiChoiceMessage) => {
        
        if(isSelected(message)) {
            const filtered = [...chosen].filter((m: MultiChoiceMessage) => m.id !== message.id);
            setChosen(filtered);
        } else {
            if(chosen.length >= 5) return;
            setChosen([...chosen, message]);
        }
    }

    const isSelected = (message : MultiChoiceMessage) : boolean => {
        return chosen.includes(message);
    }

    return (
        <div className="pt-5 pb-10 tall:pb-20">
            {messages.map((message: MultiChoiceMessage) => {

                return (
                    <div key={`multi-${message.id}`} className="inline-block m-1">
                            {message.imageUrl ? (
                                <div>
                                    <div className="w-[130px] h-[95px] bg-cover" style={{backgroundImage: `url(${message.imageUrl})`}}></div>
                                    <span>{message.label}</span>
                                </div>
                            ) : (
                                <Button label={message.label} variant={isSelected(message) ? "toggleOn" : "toggleOff"} size="small" onClick={() => handleClick(message)} />
                            )}                
                    </div>

                    
                )
            })}
            <div className="mt-10">
                <Button label="Continue" variant="primary" disabled={chosen.length < 1} symbolAfter={<ArrowRightIcon className="ml-2 mt-1 fill-white" />} onClick={() => onChoice(chosen)} />
            </div>
        </div>
    )
}

export default MultiSelect;