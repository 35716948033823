import { useEffect, useState } from "react";
import { quizLabelStyles } from "../../styles";
import { ThisOrThatContestant } from "../../types";
import ReactionButton from "../common/ReactionButton";
import StackShadows from "../common/StackShadows";
import { ReactComponent as HeartIcon } from './../../assets/icons/heart.svg';


interface ThisOrThatImageProps {
	data: ThisOrThatContestant;
    direction: string;
    nextContestant: ThisOrThatContestant | null;
    isLoser: boolean;
    numImages?: number;
    onClick: () => void;
}
const ThisOrThatImage = ({ data, direction, nextContestant, isLoser, numImages, onClick}: ThisOrThatImageProps) => {
    const [animateIn, setAnimateIn] = useState(false);
    const [active, setActive] = useState(false);
    const [hover, setHover] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    
    useEffect(() => {
        setActive(false);
        setHover(false);
        setAnimateIn(true);
    }, [data])

    const animateOut = `transition-all duration-1000 ${direction === 'left' ? 'translate-x-[-200%] rotate-[-45deg]' : 'translate-x-[200%] rotate-45'} translate-y-[20%]`;
    const animateInText = `duration-200 opacity-100`;
    const animateOutText = `duration-200 opacity-0 ${direction === 'left' ? 'translate-x-[30%] rotate-[15deg]' : 'translate-x-[-30%] rotate-[-15deg]'} translate-y-[30%]`;

    return (
        <div onClick={() => {
            if(isAnimating || isLoser) return;

            setIsAnimating(true);
            setActive(true);
            setHover(false);
            
            onClick();
            setTimeout(() => {
                setActive(false);
                setIsAnimating(false);
            }, 1000);
        }} onMouseEnter={() => {
            if(!active) setHover(true);
        }} onMouseLeave={() => {
            setHover(false);
        }} className="m-2 w-full">
            <div className={`aspect-w-[171] aspect-h-[236] cursor-pointer relative`}>
                {nextContestant && (
                    <div className={`w-full h-full rounded-[8px] overflow-hidden bg-white bg-no-repeat bg-cover bg-center-top absolute opacity-0 origin-bottom ${isLoser ? 'transition-all duration-1000 ease-out opacity-[100%]' : ''}`} style={{backgroundImage: `url(${nextContestant.asset?.file?.url})`, transform: `${isLoser ? 'scale(100%) translateY(0px)' : 'scale(90%) translateY(5px)'}`}}></div>
                )}
                <div className={`top-image w-full h-full rounded-[8px] overflow-hidden bg-white bg-no-repeat bg-cover bg-center-top absolute ${isLoser ? animateOut : ''}`} style={{backgroundImage: `url(${data.asset?.file?.url})`}}></div>
                
                <div className={`button-wrapper block w-auto h-auto absolute top-auto right-0 left-0 bottom-2 m-auto transition-all ${isLoser ? 'opacity-0' : 'opacity-100'}`}>
                    <ReactionButton variant="secondary" hoverVariant={`${hover ? 'secondaryHover' : ''}`} activeVariant={`${active ? 'secondaryActive' : ''}`}  activeDirection={`${active ? direction : null}`} symbol={<HeartIcon className={`${active ? 'fill-white' : 'fill-nightSky'}`} />} />
                </div>
            </div>
                <div className={`mt-[-1px] h-[17px]`} >
                    <StackShadows update={isLoser} numImages={numImages} />
                </div>

            <div className={`h-[56px] transition-all ${quizLabelStyles} ${isLoser ? animateOutText : ''}  ${(animateIn && !isLoser) ? animateInText : ''}`}>
                {data.title}
            </div>
        </div>
    )
}

export default ThisOrThatImage;