import { useEffect, useState } from 'react';
import ThemeProvider  from  './../context/ThemeContext';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getShowOverlay, getWindowDimensions, goToView, setShowOverlay } from '../redux/appSlice';
import ChatWrapper from './chat/ChatWrapper';
import Overlay from './Overlay';
import bgSvg from './../assets/svg/bg.svg';
import bgMediumSvg from './../assets/svg/medium-bg.svg';
import bgLargeSvg from './../assets/svg/large-bg.svg';
import { ReactComponent as MinimizeIcon } from './../assets/icons/minimize.svg';
import { ReactComponent as LogoIcon } from './../assets/icons/logo.svg';


interface ContentWrapperProps {
    onClose: () => void;
}
const ContentWrapper = ({onClose} : ContentWrapperProps) => {
    const showOverlay = useAppSelector(getShowOverlay);
    const dispatch = useAppDispatch();
    const [animateIn, setAnimateIn] = useState<boolean>(false);
    const windowDimensions = useAppSelector(getWindowDimensions);


    const maskStyles = windowDimensions ? {
        WebkitMaskImage: `url(${windowDimensions.width >= 1024 ? (windowDimensions.height > 776 ? (windowDimensions.height > 878 ? bgLargeSvg : bgMediumSvg) : bgSvg) : bgSvg})`,
        maskImage: `url(${windowDimensions.width >= 1024 ? (windowDimensions.height > 776 ? (windowDimensions.height > 878 ? bgLargeSvg : bgMediumSvg) : bgSvg) : bgSvg})`,
        WebkitMaskSize: '100%',
        maskSize: '100%',
        WebkitMaskRepeat: 'no-repeat',
        maskRepeat: 'no-repeat',
    } : {};


    useEffect(() => {
        setAnimateIn(true);

        // hacky fix for scroll position in chatui message lists.
        // some discussion of this with the maintainers here: https://github.com/chatscope/chat-ui-kit-react/issues/24
        setTimeout(() => {
            const wrapper = document.getElementsByClassName('cs-message-list__scroll-wrapper')[0];
            if (wrapper) {
                wrapper.scrollTop = wrapper.scrollHeight;
            }
        }, 1000);
    }, []);

    return (
        <div className={`w-full sm:max-w-[390px] h-[98%] tall:h-[calc(100%-40px)] max-h-[790px] tall:max-h-[700px] tallest:max-h-[840px] absolute bottom-0 tall:bottom-[40px] right-0 tall:right-[40px] overflow-hidden origin-bottom-right transition-[transform opacity] duration-500 ease-bounce pointer-events-auto ${animateIn ? 'scale-100 opacity-100' : 'scale-0 opacity-0'}`}>
            <div className="absolute top-2 left-3 text-left z-10 tall:hidden">
                <LogoIcon onClick={() => {
                    if(process.env.NODE_ENV !== 'production') {
                        dispatch(goToView('battle'));
                        dispatch(setShowOverlay(true));
                    } else {
                        //TODO not sure? track clicks maybe?
                    }
                }}/>
            </div>
    
            <div className="w-full h-full bg-no-repeat bg-origin-border" style={maskStyles}>
                {!showOverlay && (
                    <button className="text-nightSky font-bold absolute top-4 right-4 z-30" onClick={() => {
                        setAnimateIn(false);
                        setTimeout(() => {
                            onClose();
                        }, 500);
                        
                    }}><MinimizeIcon /></button>
                )}

                <ThemeProvider>
                    <div className="bg-white w-full h-full relative">
                        <ChatWrapper />
                        <Overlay windowWidth={windowDimensions ? windowDimensions.width : 0} onClose={() => dispatch(setShowOverlay(false))} />
                    </div>
                </ThemeProvider>
            
            </div>
        </div>
    )
}

export default ContentWrapper;