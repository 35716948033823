import { v4 as uuidv4 } from 'uuid';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import { useContext, useEffect, useState } from 'react';
import { WebSocketContext } from '../../context/WebSocketContext';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { startLoveOrHate, startStyleBattle, showRegistration } from '../../redux/appSlice';
import { getSessionId, getMessages, getFormattedMessages, setFormattedChat, updateChat, getCurrentFlow, getSendEvent, sendEvent, getCurrentPage } from '../../redux/chatSlice';
import { setUserCohort } from '../../redux/quizSlice';
import { ChatMessage, MultiChoiceMessage, SliderMarkProps } from '../../types';
import { useGlobalDOMEvents, arraysAreEqual } from '../../helpers/cheats';
import ChatSlider from './ChatSlider';
import MultiChoice from './MultiChoice';
import MultiSelect from './MultiSelect';
import StyleDNA from './StyleDNA';
import ImageWithGradient from '../common/ImageWithGradient';
import ResultsMessage from './ResultsMessage';
import DealsMessage from './DealsMessage';
import mixpanel from 'mixpanel-browser';


const ChatWrapper = () => {
  const dispatch = useAppDispatch();
  const sessionId = useAppSelector(getSessionId);
  const chatMessages = useAppSelector(getMessages);
  const formattedMessages = useAppSelector(getFormattedMessages);
  const currentFlow = useAppSelector(getCurrentFlow);
  const currentPage = useAppSelector(getCurrentPage);
  const event = useAppSelector(getSendEvent);

  const ws = useContext(WebSocketContext);

  const [newMessages, setNewMessages] = useState<Array<ChatMessage>>([]);
  const showInput = false;
  const [showChoices, setShowChoices] = useState<boolean>(false)
  const [multiChoiceMessage, setMultiChoiceMessage] = useState<ChatMessage | null>(null);
  const [multiSelectMessage, setMultiSelectMessage] = useState<ChatMessage | null>(null);
  const [sliderMessage, setSliderMessage] = useState<ChatMessage | null>(null);
  
  const [showResults] = useState<boolean>(false)
  const [showTypingIndicator, setShowTypingIndicator] = useState<boolean>(true)
  const [animateChoices, setAnimateChoices] = useState<boolean>(false)

  const [displayMessages, setDisplayMessages] = useState<Array<ChatMessage | undefined>>([]);
  const [konamiCode, setKonamiCode] = useState<Array<string>>([]);

  const CHAT_OFFSET_TIME = 3500;
  const CHAT_OFFSET_TIME_SHORT = 500;

  const handleSendMessage = (message: string) => {
    if(ws && sessionId) {
      ws.sendMessage(sessionId, {text: message}, 'text');
    }
  };
  
  const handleMultiChoice = (messages: MultiChoiceMessage[]) => {
    const promptMessage = chatMessages[chatMessages.length - 2];    
    const trackingPayload = {
      prompt: promptMessage.message,
      promptMessage,
      messages,
      messageLabels: messages.map(m => m.label),
    }
    mixpanel.track("Response Selected", trackingPayload)
    mixpanel.track(`Response Selected: ${promptMessage.message}`, trackingPayload)

    if(showResults) {
      //Fake messages on the frontend
      //TODO figure out if it's the last message and trigger Sign up flow
      if(newMessages.length > 0) {
        const firstObject = newMessages[0];
        const filteredMessages = [...newMessages].filter((m: ChatMessage) => m.id !== firstObject.id);

        messages.forEach((message : MultiChoiceMessage, index: number) => {
          // TODO: replace with an event map
          switch(message.event) {
            case 'start-registration':
              setTimeout(() => {
                dispatch(showRegistration());
              }, CHAT_OFFSET_TIME_SHORT);
              break;
            default:
              const responseMessage = {
                id: uuidv4(),
                direction: 'outgoing',
                type: 'text',
                message: message.label,
                position: index === 0 ? 'first' : undefined
              }

              dispatch(setFormattedChat([responseMessage]));

              if((index === messages.length - 1)) {
                setTimeout(() => {
                  setNewMessages(filteredMessages);
                }, CHAT_OFFSET_TIME_SHORT);
              }
              break;
          }
        });

      }

    } else {
      //Normal chat or quiz
      const isQuiz = currentFlow === 'quiz';
      const isCheckin = currentPage && currentPage.includes('checkin');
      if(ws && sessionId) {
        let answerIds: string[] = [];

        messages.forEach((message : MultiChoiceMessage, index: number) => {
          // TODO: replace with an event map

          switch(message.event) {
            case 'show-deals-01':
            case 'show-deals-02':
            case 'show-signup':
              const fakeMessage = {
                id: uuidv4(),
                direction: 'outgoing',
                type: 'text',
                message: message.label
              }

              setTimeout(() => {
                ws.sendMessage(sessionId, { text: message.event }, 'event');
                dispatch(updateChat([fakeMessage]));
              }, CHAT_OFFSET_TIME_SHORT);

              break;
            case 'start-registration':
              setTimeout(() => {
                dispatch(showRegistration());
              }, CHAT_OFFSET_TIME_SHORT);
              break;
            default:

              if(!isQuiz) {
                const answer = {
                  text: message.event || message.label,
                  imageUrl: message.asset ? message.asset.file.url : null
                }

                if (message.event) {
                  const fakeMessage = {
                    id: uuidv4(),
                    direction: 'outgoing',
                    type: 'text',
                    message: message.label,
                  }

                  dispatch(updateChat([fakeMessage]));
                }

                setTimeout(() => {
                  ws.sendMessage(sessionId, answer, message.event ? 'event' : 'text');
                }, 1000);
              } else {
                if(message.slug) answerIds.push(message.slug);
                const position = index === 0 ? messages.length === 1 ? 'single' : 'first' : undefined;
                const fakeMessage = {
                  id: uuidv4(),
                  direction: 'outgoing',
                  type: message.asset ? 'image' : 'text',
                  message: message.label,
                  position: position,
                  imageUrl: message.asset ? message.asset.file.url : undefined
                }

                setTimeout(() => {
                  dispatch(updateChat([fakeMessage]));
                }, CHAT_OFFSET_TIME_SHORT + (300 * index));

                setTimeout(() => {
                  if (message.customResponse) {
                    const fakeIncomingMessage = {
                      id: uuidv4(),
                      direction: 'incoming',
                      type: 'text',
                      message: message.customResponse,
                      position,
                    };
                    dispatch(setFormattedChat([fakeIncomingMessage]));
                  }
                }, CHAT_OFFSET_TIME_SHORT + (300 * (index + 0.5)));

                if((index === messages.length - 1) && isQuiz) {
                  const quizAnswer = {
                    questionId: message.quizSlug,
                    answerId: answerIds,
                    text: message.label,
                    imageUrl: message.asset ? message.asset.file.url : null
                  }
                  
                  setTimeout(() => {
                    ws.sendMessage(sessionId, quizAnswer,  isCheckin ? 'text' : 'answer', true);
                  }, CHAT_OFFSET_TIME_SHORT + (300 * index) + 100);
                }
              }
              break;
          }
        });
      }
    }

    setShowChoices(false);
    setAnimateChoices(true);
    setTimeout(() => {
      setMultiChoiceMessage(null);
      setMultiSelectMessage(null);
      setAnimateChoices(false);
    }, 800);
  };


  const handleSliderChoice = (message: SliderMarkProps) => {
    const promptMessage = chatMessages[chatMessages.length - 2]
    const trackingPayload = {
      prompt: promptMessage.message,
      promptMessage,
      messages: [message],
      messageLabels: [message.label]
    }
    mixpanel.track("Response Selected", trackingPayload)
    mixpanel.track(`Response Selected: ${promptMessage.message}`, trackingPayload)
    if(ws && sessionId) {
      const answer = {
        questionId: 'deals-preference',
        answerId: message.label.toLowerCase(),
        text: message.label
      }

      setTimeout(() => {
        ws.sendMessage(sessionId, answer, 'answer');
      }, 1000);

    }
    setShowChoices(false);
    setAnimateChoices(true);
    setTimeout(() => {
      setAnimateChoices(false);
      setSliderMessage(null);
    }, 800);
  };

  useEffect(() => {
    if(newMessages.length > 0) {

      const firstObject = newMessages[0];
      const filteredMessages = [...newMessages].filter((m: ChatMessage) => m.id !== firstObject.id);
      
      if(firstObject) {
        if(firstObject.direction === 'incoming') {
          if(firstObject.type === 'multichoice' || firstObject.type === 'multiselect' || firstObject.type === 'slider') {
            dispatch(setFormattedChat([firstObject]));
            setShowTypingIndicator(false);
            // setNewMessages(filteredMessages);
          } else if(firstObject.type === 'event') {
            //TODO Figure out better approach!
            if(firstObject.quizSlug) {
              switch (firstObject.quizSlug) {
                case 'check-in-01':
                case 'check-in-02':
                  //Send message with event
                  if(ws && sessionId) {
                    ws.sendMessage(sessionId, {text: firstObject.event}, 'event');
                  }
                  break;
                case 'dope-or-nope':
                  //Trigger game
                  setTimeout(() => {
                    dispatch(startLoveOrHate(firstObject.quizSlug || ''));
                  }, CHAT_OFFSET_TIME);
                  dispatch(setFormattedChat([firstObject]));
                  break;
                case 'style-battle-womens':
                case 'style-battle-mens':
                case 'style-battle':
                  //Trigger game
                  setTimeout(() => {
                    dispatch(startStyleBattle(firstObject.quizSlug || ''));
                  }, CHAT_OFFSET_TIME);
                  dispatch(setFormattedChat([firstObject]));
                  break;
              
                default:
                  break;
              }
            } else {
              switch (firstObject.message) {
                case 'show-results':
                  //TODO move showing results to here?
                  dispatch(sendEvent('determine-cohort'));
                  setTimeout(() => {
                    dispatch(sendEvent('show-cohort'));
                  }, CHAT_OFFSET_TIME_SHORT);
                  break;
                case 'click-to-end':
                  document.body.addEventListener('click', () => {
                    window.location.reload();
                  });
                  break;
                case 'restart':
                case 'end':
                  setTimeout(() => {
                    window.location.reload();
                  }, CHAT_OFFSET_TIME);
                  break;
                  
                default:
                  //other event, trigger it in chat
                  dispatch(sendEvent(firstObject.message));
                  break;
                      
              }
              dispatch(setFormattedChat([firstObject]));
            }
            setNewMessages([]);
          } else {
            setTimeout(() => {
              setShowTypingIndicator(true);
            }, formattedMessages.length > 0 ? (CHAT_OFFSET_TIME_SHORT * 2) : 0);

            setTimeout(() => {
              dispatch(setFormattedChat([firstObject]));
              setNewMessages(filteredMessages);
              setShowTypingIndicator(false);
            }, formattedMessages.length > 0 ? (CHAT_OFFSET_TIME) : 500);
            setMultiChoiceMessage(null);
            setMultiSelectMessage(null);
            setSliderMessage(null);
          }
        } else {
          dispatch(setFormattedChat([firstObject]));
          setNewMessages(filteredMessages);
        }
      }
    }
  // eslint-disable-next-line
  }, [newMessages, dispatch, ws, sessionId])
  

  useEffect(() => {      
      const newMessages:ChatMessage[] = chatMessages.filter((message: ChatMessage) =>  !formattedMessages.some(oldMessage => message.id === oldMessage.id));
      setNewMessages(newMessages);
  // eslint-disable-next-line
  }, [chatMessages]);


  useEffect(() => {
    if(ws && sessionId) {
      ws.startChat(sessionId);
    }
  }, [sessionId, ws]);


  useEffect(() => {
    if(ws && sessionId && event) {
      ws.sendMessage(sessionId, {text: event}, 'event', true);
      dispatch(sendEvent(null));
    }
  }, [dispatch, sessionId, ws, event]);


  useEffect(() => {
    const lastObject = formattedMessages[formattedMessages.length - 1];    
    setShowTypingIndicator(false);

    if(lastObject) {
      setTimeout(() => {
        if(lastObject.type === 'multichoice') {
          //Show Buttons
          setMultiChoiceMessage(lastObject);
          setShowTypingIndicator(false);
          setShowChoices(true);
        }
        if(lastObject.type === 'multiselect') {
          //Show Buttons
          setMultiSelectMessage(lastObject);
          setShowTypingIndicator(false);
          setShowChoices(true);
        }
        if(lastObject.type === 'slider') {
          //Show Slider
          setSliderMessage(lastObject);
          setShowTypingIndicator(false);
          setShowChoices(true);
        }
      }, CHAT_OFFSET_TIME / 2);
      
      //For styling
      let counter = 0;
      let currentDirection = 'incoming';
      const indexedNewMessages = [...formattedMessages].reverse().map((m: ChatMessage) => {
        let position = m.position || 'normal';

        // new direction
        if(currentDirection !== m.direction || m.type === 'cohort-hero') {
          counter = 0;
          currentDirection = m.direction;
        }
        
        if(counter === 0 && position !== 'single') position = 'last';
        if(counter > 0 && position === 'single') position = 'first';

        const newM:ChatMessage = {
          ...m,
          displayIndex: counter,
          position: position
        }

        if(m.type === 'text') counter++;
        
        return newM;
      });
      setDisplayMessages(indexedNewMessages.reverse());
    }
  }, [formattedMessages]);
  


  const beforeTeal = "before:block before:w-full before:h-[70px] before:absolute before:top-[-69px] before:left-0 before:border-t-0 before:border-t-transparent before:border-r-0 before:border-r-transparent before:border-b-[70px] before:border-b-teal before:border-l-[400px] before:border-l-transparent   relative inline-block"
  const afterTeal = "after:block after:w-full after:h-[70px] after:absolute after:bottom-[-69px] after:bg-teal relative inline-block";

  const getMessageFromType = (message: ChatMessage) => {
    switch (message.type) {
      case 'text':
        return (
          <Message.TextContent text={message.message} className="text-left" />
        )
      case 'image':
        return (
          <Message.CustomContent>
            <div>
              <ImageWithGradient imageUrl={message.imageUrl || ''} width="w-[184px]" height="h-[264px]" label={message.message} />
            </div>
          </Message.CustomContent>
        )
      case 'cohort-hero':
        return (
          <Message.CustomContent>
            <StyleDNA data={message || null} />
          </Message.CustomContent>
        )
      case 'results':
        return (
          <Message.CustomContent>
            <ResultsMessage message={message.message} />
          </Message.CustomContent>
        )
      case 'deals':
        return (
          <Message.CustomContent>
            <DealsMessage message={message} />
          </Message.CustomContent>
        )
      case 'sign-up':
        return (
          <Message.CustomContent>
            <DealsMessage message={message} />
          </Message.CustomContent>
        )
      default:
        break;
    }
  }

  useGlobalDOMEvents({
    keyup(ev: KeyboardEvent) {
      if (konamiCode.length > 0 && (konamiCode[0] !== 'ArrowUp' || konamiCode.length > 11)) {
        setKonamiCode([]);
      } else {
        setKonamiCode(codes => [...codes, ev.code]);
      }
    },
  });

  useEffect(() => {
    if (arraysAreEqual(konamiCode, ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'KeyB', 'KeyA', 'Enter'])) {
      console.log('%cCHEATS ACTIVATED', 'font-size: 36px; font-weight: bold; color: red;');

      dispatch(setUserCohort({
        category: 'womens',
        cohort: 'glam-squad',
      }));

      dispatch(showRegistration());

      setKonamiCode([]);
    }
  }, [dispatch, konamiCode]);

  return (
    <div className="w-full h-full flex flex-col justify-end">
      <div className="w-full h-[100px] absolute top-0 z-10 shrink-0 bg-gradient-to-b from-white"></div>

      {displayMessages && displayMessages.length > 0 ? (
        <MainContainer className={`bg-transparent border-none h-auto tall:pb-2`}>
            <ChatContainer className={`bg-transparent`}>
              <MessageList className={`bg-transparent ${showTypingIndicator ? 'has-typing-indicator' : ''} ${showChoices ? 'showing-choices' : ''}`} typingIndicator={showTypingIndicator ? <TypingIndicator /> : null}>
                {displayMessages.map((message: any, index: number) => {

                  return (message.type !== 'multichoice' && message.type !== 'multiselect' && message.type !== 'slider'  && message.type !== 'event') ? (
                    <Message
                      className={`message--${message.type} ${(message.displayIndex > -1) ?  `count-${message.displayIndex}` : ''} animate-in`}
                      key={message.id}
                      model={{
                        direction: message.direction,
                        position: message.position || 'normal'
                      }}
                      >                       
                      {getMessageFromType(message)}
                    </Message>
                  ) : (
                    ''
                  )
                })}
              </MessageList>
              
              {showInput && (
                <MessageInput placeholder="Type message here" attachButton={false} onSend={handleSendMessage} />
              )}
            </ChatContainer>
        </MainContainer>

      ) : (
        <p className="absolute top-[50%] left-0 right-0 text-nightSky font-roobertSemiBold">Loading...</p>
      )}

      

      
      <div className={`w-full bg-teal ${beforeTeal} ${afterTeal} transition-transform ease-softBounce duration-700 z-10`} style={{position: animateChoices ? 'absolute' : 'relative', transform: showChoices ? 'translateY(0)' : 'translateY(calc(100% + 70px))', transitionDelay: showChoices ? '150ms' : '0ms'}}>
        {multiChoiceMessage && multiChoiceMessage.choices && (
          <MultiChoice messages={multiChoiceMessage.choices} onChoice={(message:MultiChoiceMessage) => handleMultiChoice([message])} />
        )}
        {multiSelectMessage && multiSelectMessage.choices && (
          <MultiSelect messages={multiSelectMessage.choices} onChoice={(messages:MultiChoiceMessage[]) => handleMultiChoice(messages)} />
        )}
        {sliderMessage && (
          <ChatSlider onChoice={(message) => handleSliderChoice(message)} />
        )}
      </div>
    </div>
  );
};

export default ChatWrapper;
