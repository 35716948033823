import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { QuizContent, QuizResponseType, UserCohort } from '../types';

export interface QuizState {
  responses: QuizResponseType[];
  styleBattleDone: boolean;
  loveOrHateDone: boolean;
  quizContent: QuizContent[];
  userCohort: UserCohort | null;
  quizIndex: number;
}

const initialState: QuizState = {
	responses: [],
	styleBattleDone: false,
	loveOrHateDone: false,
	quizContent: [],
	userCohort: null,
	quizIndex: 0,
};


export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    addQuizContent: (state, action: PayloadAction<Array<QuizContent>>) => {
		state.quizContent = action.payload;
	},
    addResponse: (state, action: PayloadAction<QuizResponseType>) => {
		const responseObject = {
			id: action.payload.id,
			response: action.payload.response
		}

		const responseIndex = [...state.responses].findIndex((r:QuizResponseType) => r.id === responseObject.id);
		if(responseIndex > -1) {
			const filteredResponses = [...state.responses].filter((r:QuizResponseType) => r.id !== responseObject.id);
			state.responses = [
				...filteredResponses,
				responseObject
			]
		} else {
			state.responses = [
				...state.responses,
				responseObject
			]
		}
    },
	resetResponses: (state) => {
		state.responses = [];
    },
	quizDone: (state, action: PayloadAction<string>) => {
		switch (action.payload) {
			case 'style-battle':
				state.styleBattleDone = true;
				break;
			case 'dope-or-nope':
				state.loveOrHateDone = true;
				break;
		
			default:
				break;
		}
    },
	setUserCohort: (state, action: PayloadAction<UserCohort>) => {
		state.userCohort = action.payload;
    },
	setCurrentQuizIndex: (state, action:PayloadAction<number>) => {
		state.quizIndex = action.payload;
	}
  },
});

export const { addQuizContent, addResponse, resetResponses, quizDone, setUserCohort, setCurrentQuizIndex } = quizSlice.actions;

export const getResponses = (state: RootState) => state.quiz.responses;
export const getStyleBattleDone = (state: RootState) => state.quiz.styleBattleDone;
export const getLoveOrHateDone = (state: RootState) => state.quiz.loveOrHateDone;
export const getQuizContent = (state: RootState) => state.quiz.quizContent;
export const getUserCohort = (state: RootState) => state.quiz.userCohort;
export const getQuizIndex = (state: RootState) => state.quiz.quizIndex;


export default quizSlice.reducer;
