import React from 'react';
import { AssetFile, ChatMessage } from '../../types';

interface DealsMessageProps {
    message: ChatMessage;
}

const DealsMessage = ({ message } : DealsMessageProps) => {

    const tileStyles = `w-full aspect-w-1 aspect-h-1 bg-teal overflow-hidden rounded-[10px]`;
    const tileContent = `w-full bg-blueLight bg-[length:101%_auto] bg-top bg-no-repeat flex flex-col justify-end`;
    const tileText = `w-full pt-2 pb-4 bg-blueLight text-nightSky font-roobertSemiBold text-[14px] leading-[17px]`;

    return (
        <div className="bg-nightSky">
            {message.type === 'sign-up' ? (
                <React.Fragment>
                    <div className="mb-8">
                        <h3 className={`text-[30px] leading-[40px] mb-2`}>{message.dealsContent?.heading}</h3>
                        <p className={`text-base`}><span className={`font-roobertBold`}>{message.dealsContent?.subheading}</span><br/>{message.dealsContent?.description}</p>
                    </div>
                    <div className="w-full mb-2 grid grid-cols-2 grid-rows-2 gap-2">
                        {message.assets?.map((asset : AssetFile, index: number) => (
                            <div key={`${message.id}-${index}`} className={tileStyles}><div className={tileContent} style={{backgroundImage: `url(${asset.file.url})`}}><div className={tileText}>{asset.title}</div></div></div>
                       ))}
                    </div>
                </React.Fragment>
            ) : (
                <div className="mb-8 p-[14px] bg-blueLight rounded-[10px]">
                    <a href={message.link} target="_blank" rel="noreferrer">
                        <div className="aspect-w-1 aspect-h-1 bg-contain bg-center" style={{backgroundImage: `url(${message.asset?.file.url})`}}></div>
                        <p className="pt-[20px] pb-[16px] text-nightSky text-[28px] leading-[36px]">{message.asset?.brand} {message.asset?.title}</p>
                    </a>
                </div>
            )}
        </div>
    )
}

export default DealsMessage;