import React, { useEffect, useState } from 'react'
import mixpanel from 'mixpanel-browser';
import ContentWrapper from './ContentWrapper';
import WidgetButton from './WidgetButton';
import staticBg from './../assets/images/ad-desktop.jpg';
import staticBgMobile from './../assets/images/ad-mobile.jpg';
import "slick-carousel/slick/slick.css"; 
import { useAppDispatch, useAppSelector } from '../hooks';
import { getWindowDimensions, setWindowDimensions } from '../redux/appSlice';



export const Main: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const windowDimensions = useAppSelector(getWindowDimensions);

  mixpanel.init('cca58bcceaf585b08d336a294ec2ce78', {debug: true}); 

  useEffect(() => {
    const handleWindowResize = () => {
      dispatch(setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      }));
    }

    window.addEventListener('resize', handleWindowResize);    
    handleWindowResize();
    
    return () => {            
        window.removeEventListener('resize', handleWindowResize);  
    }
}, [dispatch]);

  return (
    <div >
      {windowDimensions && windowDimensions.width ? (
        <div className={`w-[100vw] h-[100vh] relative overflow-x-hidden overflow-y-auto bg-cover bg-top`}>          
          <div className={`fixed w-full h-[100%] pointer-events-none z-10`} >
            <div className={`absolute w-full h-full bg-[#000000] transition-all ${isOpen ? 'opacity-60' : 'opacity-0'}`}></div>
            {isOpen && (
              <ContentWrapper onClose={() => setIsOpen(false)} />
              )}
          </div>
          
          <div className='w-full aspect-w-[39] aspect-h-[202] lg:aspect-w-[192] lg:aspect-h-[240] bg-cover bg-top' style={{cursor: "pointer", backgroundImage: `url(${windowDimensions.width >= 1024 ? staticBg : staticBgMobile})`}}  onClick={() => {
            if(!isOpen) {
              setIsOpen(true)
              mixpanel.track('Open Chat From Ad');
            }
          }}></div>


          <WidgetButton
            onClick={() => {
                setIsOpen(!isOpen)
                isOpen ? mixpanel.track('Close Chat From Widget') : mixpanel.track('Open Chat From Widget');
              }
            }
            isOpen={isOpen}
            dimensions={windowDimensions}
          />
          
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default Main;